
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Unavbar from './Unavbar';
import Footer from './Footer';

const Postdetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const post = location.state?.selectedPost;

  if (!post) {
    return <p>No post details available.</p>;
  }

  return (
    <>
     <Unavbar />
     <section className="hero-section" id="section_1">
     <div className="video-wrap">
     <img src={post.photo} alt={post.name}   className="custom-video" id="imgym" />
     </div>
    </section>
    <section className="about-section">
  <div className="container">
    <h2 className="radiant mt-2" style={{fontSize: '40px'}} >{post.name}</h2>
    <hr />
  </div>
</section>

  <section>
  <div className="container">
      <p className='' style={{ textAlign: 'justify', fontWeight: 500 }}>
      {post.description}
      </p>
    </div> 
  </section>
    <Footer />
    
    </>
    
  );
}

export default Postdetails;
