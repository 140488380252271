import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Code from '../components/Code';
import { Helmet } from 'react-helmet';
import Api from '../components/Api';

function Register() {
    const [role, setRole] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [flat, setFlat] = useState("");
    const [tower, setTower] = useState("");
    const [pdf, setPdf] = useState("");
    const [password, setPassword] = useState("");
    const [registerStatus, setRegisterStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleRoleChange = (event) => {
        setRole(event.target.value);
    };

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const validatePhone = (phone) => {
        const phonePattern = /^[0-9]{10}$/; 
        return phonePattern.test(phone);
    };

    const register = async (e) => {
        e.preventDefault();

        if (!validateEmail(email)) {
            setRegisterStatus("Invalid email address.");
            return;
        }

        if (!validatePhone(phone)) {
            setRegisterStatus("Invalid phone number. Please enter a 10-digit phone number.");
            return;
        }

        setLoading(true);
        try {
            const response = await Api.post("/uregister", {
                role,
                name,
                email,
                phone,
                flat,
                tower,
                pdf,
                password,
            });
            if (response.data.message) {
                navigate('/security-verification', { state: {phone} }); 
            } else {
                setRegisterStatus("Account creation pending.");
            }
        } catch (error) {
            setRegisterStatus("Registration failed. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
       <Helmet>
        <title>Register at Ajnara Daffodil</title>
        <meta name="title" property="og:title" content="Register at Ajnara Daffodil" />
        <meta name="description" content="Register at Ajnara Daffodil to access exclusive resident benefits and services. Join Ajnara Daffodil community and experience luxury living at its finest." />
        <meta property="og:description" content="Register at Ajnara Daffodil to access exclusive resident benefits and services. Join Ajnara Daffodil community and experience luxury living at its finest." />
       </Helmet>
            <style dangerouslySetInnerHTML={{ __html: "body{background: rgb(63,94,251); background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);}\n.navbar {background-color: #babebf;} .hero-section{height: auto;}" }} />
            <Navbar />
            <div className="container hero-section">
                <div className="row justify-content-center p-2">
                    <div className="col-lg-5 col-12 card p-4 bg-light">
                        <div className="row ">
                            <div className="col-lg-6 col-6">
                                <Link to="/register" className="btn btn-primary w-100"> <i className="bi bi-person-plus-fill"></i>&nbsp; Register</Link>
                            </div>
                            <div className="col-lg-6 col-6">
                                <Link to="/login" className="btn btn-secondary text-center w-100"> <i className="bi bi-box-arrow-in-right"></i> &nbsp;Back Login </Link>
                            </div>
                        </div>
                        
                            <form onSubmit={register}>
                                <div className="row mt-2">
                                    <div className="col-12">
                                        <label className="mt-3"><b>I am an/a</b> </label>
                                    </div>
                                    <div className="col-4 mt-2">
                                        <label>
                                            <input type="radio" value="owner" checked={role === 'owner'} onChange={handleRoleChange} required />
                                            &nbsp; Owner
                                        </label>
                                    </div>
                                    <div className="col-4 mt-2 text-center">
                                        <label>
                                            <input type="radio" value="tenant" checked={role === 'tenant'} onChange={handleRoleChange} />
                                            &nbsp; Tenant
                                        </label>
                                    </div>
                                    <div className="col-4 mt-2 text-center">
                                        <label>
                                            <input type="radio" value="guest" checked={role === 'guest'} onChange={handleRoleChange} />
                                            &nbsp; Guest
                                        </label>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col-lg-4 col-12 mt-4">
                                        <label htmlFor="name"><b>Name</b> <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-lg-8 col-12 mt-3">
                                        <input className="w-100 form-control" type="text" name="name" onChange={(e) => setName(e.target.value)} placeholder="Enter your Name" required />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                <div className="col-lg-4 col-12 mt-4">
                                        <label htmlFor="email"><b>Email-ID</b> <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-lg-8 col-12 mt-3">
                                        <input className="w-100 form-control" type="text" name="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter your Email Address" required />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                <div className="col-lg-4 col-12 mt-4">
                                        <label htmlFor="phone"><b>Phone</b> <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-lg-8 col-12 mt-3">
                                        <div className="row">
                                            <div className="col-4">
                                                <Code />
                                            </div>
                                            <div className="col-8">
                                            <input className="w-100 form-control" type="text" name="phone" onChange={(e) => setPhone(e.target.value)} placeholder="Enter Number" required />
                                            </div>
                                        </div>
                                     
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-12 mt-4">
                                        <div className="row">
                                            <div className="col-lg-4 col-4 mt-2">
                                                <label htmlFor="flat"><b>Flat</b> <span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-lg-8 col-8">
                                                <input className="w-100 form-control" type="text" name="flat" onChange={(e) => setFlat(e.target.value)} placeholder="Enter Flat" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-12 mt-4">
                                        <div className="row">
                                        <div className="col-lg-4 col-4 mt-2">
                                                <label htmlFor="tower"><b>Tower</b><span className="text-danger">*</span></label>
                                            </div>
                                            <div className="col-lg-8 col-8">
                                                <input className="w-100 form-control" type="text" name="tower" onChange={(e) => setTower(e.target.value)} placeholder="Enter Tower" required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <b> Ajnara Daffodil , Noida. </b>
                                    </div>
                                    <div className="col-lg-12">
                                        <label className="text-dark mt-3"><b>Upload Address Proof</b>&nbsp;(Optional)</label>
                                        <input
                            className="mt-2 form-control"
                            type="file"
                            name="pdf"
                            onChange={(e) => setPdf(e.target.value)}
                          />
                            <span className='text-danger'>Note:  &nbsp; </span> 
                            Accepted Images: jpeg, png. Max file size 2Mb each
                                    </div>
                                </div>
                                <div className="row mt-3">
                                <div className="col-lg-4 col-12 mt-4">
                                        <label htmlFor="password"><b>Password</b> <span className="text-danger">*</span></label>
                                    </div>
                                    <div className="col-lg-8 col-12 mt-3">
                                        <input className="w-100 form-control" type="password" name="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter your Password" required />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col-12">
                                        <button className="btn text-white mt-4 w-100 bg-primary" type="submit" disabled={loading}>
                                            {loading ? 'Registering...' : 'Continue'}
                                        </button>
                                    </div>
                                </div>
                                {registerStatus && <h1 style={{ fontSize: '15px', textAlign: 'center', marginTop: '20px', color:'red'}}>{registerStatus}</h1>}
                            </form>
                        
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;
