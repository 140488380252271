
import React from "react";

const Footer = () => {
    return(
   <>
        <footer className="site-footer"> 
          <div className="container mt-2 mb-5">
            <div className="row">
              <div className="col-lg-7 col-12 mt-5">
               <img src="../assets/images/logo1re.png" alt="image-logo" className="w-25 card p-2" 
                   style={{ backgroundColor: 'rgba(189, 186, 186, 0.692)' }}></img>
                 
               </div>
              <div className="col-lg-5 col-12 mt-5">
                    <h5 className="text-white  p-1" style={{ backgroundColor: '#6c757d87', fontWeight: 600 }}>About Ajnara Daffodil 
                      <br /> Communication Update
                      </h5>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-lg-7 col-12 mt-5 order-2 order-lg-1">
                <div className="text-white mt-5" >
                <span style={{ backgroundColor: '#6c757d87', fontSize: '19px' }}>
                   <i className="bi bi-geo-alt-fill"/> &nbsp;  
                   Gate Number 1, Sector 137, Noida, Uttar Pradesh.
                    
                  </span>
                </div>
             
               </div>
              <div className="col-lg-5 col-12 mt-5 order-1 order-lg-2">
              <div class="input-group w-auto mt-5 mb-5">
                        <input type="text" class="form-control p-2" placeholder="Your eamil address" aria-label="Your eamil address" aria-describedby="button-addon1" />
                        <button data-mdb-button-init="true" data-mdb-ripple-init="true" class="btn btn-danger " type="button" id="button-addon1" data-mdb-ripple-color="dark">Get Notified</button>
                        </div>
              </div>
            </div>
          </div>   

          <div className="container-fluid  p-3  " style={{ marginTop: '10px', backgroundColor: 'rgba(58, 56, 56, 0.5)', backfaceVisibility: '0.8' }}>
            <div className="row">
              <div className="col-lg-6 col-12 text-center">
                <a  style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }}> Ajnara Daffodil , All
                  rights reserved.</a>
              </div>
              <div className="col-lg-6 col-12 text-center">
                <a href="https://www.ratnkunj.com/" style={{ color: 'rgb(247, 231, 2)', fontSize: '18px' }} target="_blank" rel="noopener noreferrer">Managed by
                  Ratnkunj.com </a>
              </div>
            </div>
          </div>
        </footer>
   </>

    );
}

export default Footer;