import React, { useState, useEffect  } from 'react';
import Api from './Api'; 
import Code from './Code';
function Option() {
  const [selectedOption, setSelectedOption] = useState('');
  const [showTextField, setShowTextField] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    purpose: '',
    fromDateTime: '',
    toDateTime: '',
    my_class_id: '', // Initialize with an empty string or default value
  });

  useEffect(() => {
    // Update my_class_id based on formData.otherPlace or selectedOption
    setFormData(prevData => ({
      ...prevData,
      my_class_id: formData.otherPlace || selectedOption,
    }));
  }, [formData.otherPlace, selectedOption]); // Dependencies for when to run this effect
  const [errors, setErrors] = useState({});
  const [availabilityResponse, setAvailabilityResponse] = useState(null); 
 
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setShowTextField(selectedValue === 'other'); 
  };
 
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
 
  const validate = () => {
    const newErrors = {};
    if (!formData.fromDateTime) newErrors.fromDateTime = 'From date and time are required';
    if (!formData.toDateTime) newErrors.toDateTime = 'To date and time are required';
    if (new Date(formData.fromDateTime) >= new Date(formData.toDateTime)) newErrors.dateTime = 'From date must be before To date';
    if (!selectedOption && !formData.otherPlace) newErrors.place = 'Place selection is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validate()) return; 
  
    try {
      const checkAvailabilityResponse = await Api.post('/booking-area', formData);
   
      setAvailabilityResponse(checkAvailabilityResponse.data);
  
      if (checkAvailabilityResponse.data && checkAvailabilityResponse.data.message) {
        alert(checkAvailabilityResponse.data.message);
      }
    } catch (error) {
      console.error('Error during booking:', error);
      alert('An error occurred during booking. Please try again.');
    }
  };

  const handleSubmit2 = async (event) => {
    event.preventDefault();
    if (!validate()) return;
    const availabilityData = {
      my_class_id: formData.otherPlace || selectedOption,
      from_booking_datetime: formData.fromDateTime,
      to_booking_datetime: formData.toDateTime
    };
    try {
      const checkAvailabilityResponse = await Api.post('/booking-checkavailibility', availabilityData);
   
      setAvailabilityResponse(checkAvailabilityResponse.data);
    
      if (checkAvailabilityResponse.data && checkAvailabilityResponse.data.message) {
        alert(checkAvailabilityResponse.data.message);
      }
    } catch (error) {
      console.error('Error during booking:', error);
      alert('An error occurred during booking. Please try again.');
    }
  };
  return (
    <section>
      <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content bg-info">
            <div className="modal-header">
              <h3 className="radiant" id="exampleModalLabel">Ajnara Daffodil</h3>
              <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close"><b>Close</b></button>
            </div>
            <div className="modal-body" style={{ backgroundColor: '#f7f7f7' }}>
              <div className="row ms-2">
                <div className="col-12">
                  <label className="mb-2"><b>Select Your Place</b> <span className="text-danger">*</span></label>
                </div>
                <div className="row">
                  <div className="col-5">
                    <select className="form-select" value={selectedOption} onChange={handleSelectChange}>
                      <option value="">Select...</option>
                      <option value="Banquet Hall">Banquet Hall</option>
                      <option value="other">Other</option>
                    </select>
                    {errors.place && <div className="text-danger">{errors.place}</div>}
                  </div>
                  <div className="col-7">
                    {showTextField && (
                      <input type="text" className="form-control" placeholder="Enter choice" name="otherPlace" value={formData.otherPlace} onChange={handleInputChange} />
                    )}
                  </div>
                </div>
                <label className="mt-3"><b>See Availability</b> <span id="timedate">(Select Date &amp; Time)</span> <span className="text-danger">*</span></label>
                <div className="row">
                  <div className="col-lg-6 col-12 mt-2">
                    <label className='w-100'>Start Date & Time:
                      <input className="form-control w-100" type="datetime-local" name="fromDateTime" value={formData.fromDateTime} onChange={handleInputChange} />
                    </label>
                    {errors.fromDateTime && <div className="text-danger">{errors.fromDateTime}</div>}
                  </div>
                  <div className="col-lg-6 col-12 mt-2">
                    <label className='w-100'>End Date & Time:
                      <input className="form-control w-100" type="datetime-local" name="toDateTime" value={formData.toDateTime} onChange={handleInputChange} />
                    </label>
                    {errors.toDateTime && <div className="text-danger">{errors.toDateTime}</div>}
                    {errors.dateTime && <div className="text-danger">{errors.dateTime}</div>}
                  </div>
                  <div className="col-lg-4 col-12 mt-3">
                    <button type="submit" onClick={handleSubmit2} className="btn btn-danger w-100 p-1">Check Now</button>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4 col-12">
                    <label htmlFor="name"><b>Your Name</b><span className="text-danger">*</span></label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input type="text" id="name" placeholder="Enter Your Name" className="form-control" name="name" value={formData.name} onChange={handleInputChange} />
                    {errors.name && <div className="text-danger">{errors.name}</div>}
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-lg-4 col-12 mt-2">
                    <label htmlFor="phone"><b>Phone</b> <span className="text-danger">*</span></label>
                  </div>
                  <div className="col-lg-8 col-12 mt-2">
                    <div className="row">
                      <div className="col-4">
                        <Code />
                      </div>
                      <div className="col-8">
                        <input className="w-100 form-control" type="text" name="phone" placeholder="Enter Number" value={formData.phone} onChange={handleInputChange} />
                        {errors.phone && <div className="text-danger">{errors.phone}</div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-4 col-12">
                    <label htmlFor="email"><b>Your Email</b><span className="text-danger">*</span></label>
                  </div>
                  <div className="col-lg-8 col-12">
                    <input type="email" id="email" placeholder="Enter Your Email" className="form-control" name="email" value={formData.email} onChange={handleInputChange} />
                    {errors.email && <div className="text-danger">{errors.email}</div>}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <label htmlFor="textarea"><b>Purpose Of Booking</b> </label>
                  </div>
                  <div className="col-12 mt-2">
                    <textarea id="textarea" rows={4} placeholder="Enter Your Messages" className="form-control h-75" name="purpose" value={formData.purpose} onChange={handleInputChange}></textarea>
                    {errors.purpose && <div className="text-danger">{errors.purpose}</div>}
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-4 col-6 mt-2">
                    <button type="submit" onClick={handleSubmit} className="btn btn-danger  w-100">Book Now</button>
                  </div>
                  <div className="col-lg-4 col-1 mt-2"></div>
                  <div className="col-lg-4 col-5 mt-2">
                    <button type="button" className="btn btn-danger  w-100" data-bs-dismiss="modal" aria-label="Close">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Option;
